import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapContainer from './MapContainer';
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import toast, { Toaster } from 'react-hot-toast';
import uuid from 'react-uuid'
import ReactStars from "react-rating-stars-component";
import moment from 'moment';
const mapStyle = {
    width: '100%',
    height: 800,
    position: 'absolute'
}

const Map = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiZmFicmljOCIsImEiOiJjaWc5aTV1ZzUwMDJwdzJrb2w0dXRmc2d0In0.p6GGlfyV-WksaDV_KdN27A'
});



class FrontendHomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            loader: false,
            companyName: '',
            lastname: '',
            firstname: '',
            email: '',
            emailSecond: '',
            phone: '',
            adres: '',
            postal: '',
            city: '',
            country: '',
            kvk: '',
            arcticles: '',
            typeFood: '',
            electricity: '',
            electricityConnection: '',
            electricityWattage: '',
            carSize: '',
            alreadyBeenThere: '',
            desc: '',
            redirect: false,
            typeShop: '',
            shopAmount: 0,
            typeAmountShop: '',
            genrale: false,
            policy: false,
            totalPayments: [],
            orderId: '',
            extraMeter: false,
            shopAmountExtra: 0,
            items: [],
            errorMail: false,
            emailSecond: ''
        };
    }

    controlSecondEmail(mail) {
        this.setState({
            emailSecond: mail,
            errorMessage: ''
        })

        setTimeout(() => {
            if (this.state.emailSecond != this.state.email) {
                this.setState({
                    errorMail: true
                })

            } else {
                this.setState({
                    errorMail: false
                })
            }
        }, 2000)
    }


    componentDidMount() {
        //window.location.replace("https://marktcom-beek.nl/");
        let payments = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/');
        payments.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let payment = childSnap.val();

                //let newImage = {id: this.products[i].winkelier,url:newImages.url, productId:this.products[i].id}

                this.setState(prevState => ({
                    totalPayments: [...prevState.totalPayments, payment],
                }));

                console.log(this.state.totalPayments)

            });
        })

        let paymentsTwo = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requests/');
        paymentsTwo.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let payment = childSnap.val();

                //let newImage = {id: this.products[i].winkelier,url:newImages.url, productId:this.products[i].id}

                this.setState(prevState => ({
                    totalPayments: [...prevState.totalPayments, payment],
                }));

            });
        })

        let paymentsThree = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/baek-voiture/');
        paymentsThree.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let payment = childSnap.val();

                //let newImage = {id: this.products[i].winkelier,url:newImages.url, productId:this.products[i].id}

                this.setState(prevState => ({
                    totalPayments: [...prevState.totalPayments, payment],
                }));

            });
        })

        setTimeout(() => {

            let date = new Date();
            let lastNumber = this.state.totalPayments.length > 99 ? this.state.totalPayments.length : this.state.totalPayments.length > 9 ? '0' + this.state.totalPayments.length : '00' + this.state.totalPayments.length;
            let DayOfMonth = Number(date.getDate()) > 9 ? date.getDate() : '0' + date.getDate();
            let orderName = "JM" + date.getFullYear() + '0' + (Number(date.getMonth()) + 1) + DayOfMonth + lastNumber;

            this.setState({
                orderId: orderName
            })
            console.log(this.state.totalPayments.length)
            console.log("orderId is: " + this.state.orderId)

        }, 1000);


    }

    checkItems() {
        let item = []
        if (this.state.typeShop == 'Standaard marktkraam zonder zeil (€ 55,-- per stuk)' && this.state.extraMeter == false) {
            item = [
                { amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 55 },
            ]


        } else if (this.state.typeShop == 'Grondplaats voor eigen materiaal (€ 10,50  per meter)' && this.state.extraMeter == true) {
            item = [
                { amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 55 },
                { amount: Number(this.state.shopAmountExtra), name: "Grondplaats voor eigen materiaal (€ 10,50  per meter)", price: 10.50 },
            ]


        }
        else if (this.state.typeShop == 'Grondplaats voor eigen materiaal (€ 10,50  per meter)') {
            item = [{ amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 10.50 },]


        }
        else if (this.state.typeShop == 'Grondplaats voor verkoopwagen  (€ 10,50  per meter. Totale lengte incl. dissel)') {
            item = [
                { amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 10.50 },

            ]


        }


        return item
    }

    generateUniqueOrderIDMarkcom() {
        return Firebase.database().ref('/clients/-907c8a70q98nlcnl9/OrderNumber').transaction((currentOrderNumber) => {
            return (currentOrderNumber || 0) + 1;
        }).then((result) => {
            if (result.committed) {
                // Formatteer de huidige datum als "YYYYMMDD"
                const formattedDate = moment().format('YYYYMMDD');
                // Zorg dat het unieke nummer minstens twee cijfers heeft
                const formattedOrderNumber = String(result.snapshot.val()).padStart(3, '0');
                // Voeg "BM" toe en combineer met de geformatteerde datum en het unieke nummer
                return `JM${formattedDate}${formattedOrderNumber}`;
            } else {
                throw new Error('Transactie niet geslaagd.');
            }
        });
    }

    async sendRequest() {

        this.setState({
            loader: true,
        })
        let date = new Date();
        /* let lastNumber = this.state.totalPayments.length > 99 ? this.state.totalPayments.length : this.state.totalPayments.length > 9 ? '0' + this.state.totalPayments.length : '00' + this.state.totalPayments.length;
         let DayOfMonth = Number(date.getDate()) > 9 ? date.getDate() : '0' + date.getDate();
         let orderName = "JM" + date.getFullYear() + '0' + (Number(date.getMonth()) + 1) + DayOfMonth + lastNumber;
 
         this.setState({
             orderId: orderName
         })*/

        let Order_ID = await this.generateUniqueOrderIDMarkcom();
        setTimeout(() => {
            localStorage.setItem('email', this.state.email)




            var key = Firebase.database().ref().child('/clients/-907c8a70q98nlcnl9/requests/').push().key;
            Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requests/' + key).set({
                id: key,

                orderId: Order_ID,
                companyName: this.state.companyName,
                lastname: this.state.lastname,
                firstname: this.state.firstname,
                email: this.state.email,
                phone: this.state.phone,
                adres: this.state.adres,
                postal: this.state.postal,
                city: this.state.city,
                kvk: this.state.kvk,
                typeFood: this.state.typeFood,
                arcticles: this.state.arcticles,
                country: this.state.country,
                electricity: this.state.electricity,
                electricityConnection: this.state.electricityConnection,
                electricityWattage: this.state.electricityWattage,
                carSize: this.state.carSize,
                items: this.state.typeShop == 'Standaard marktkraam zonder zeil' && this.state.extraMeter == false ? [{ amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 55 }] : this.state.typeShop == 'Standaard marktkraam zonder zeil' && this.state.extraMeter == true ? [
                    { amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 55 },
                    { amount: Number(this.state.shopAmountExtra), name: "Grondplaats voor eigen materiaal)", price: 10.50 },
                ] : this.state.typeShop == 'Grondplaats voor eigen materiaal' ? [{ amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 10.50 },] : this.state.typeShop == 'Grondplaats voor verkoopwagen' ? [
                    { amount: Number(this.state.shopAmount), name: this.state.typeShop, price: 10.50 },

                ] : null,
                invoicePayed: 'In afwachting',
                status: 'In afwachting',
                alreadyBeenThere: this.state.alreadyBeenThere,
                desc: this.state.desc,
                newOrder: true,
                typeShop: this.state.typeShop,
                status: 'In afwachting',
                shopAmount: this.state.shopAmount,
                typeAmountShop: this.state.typeShop == 'Standaard marktkraam zonder zeil (€ 55,-- per stuk)' ? 'Aantal' : 'Meter',
                price: {
                    check: 'in progress'
                },
                rating: {
                    check: 'in progress'
                }
            }).then(() => {
                let headers = new Headers();

                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('Origin', 'http://localhost:3000');
                //headers.append('Origin','https://frontend-test-bbo.web.app/')
                headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
                fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/confirmMarktcommissie", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({ email: this.state.email, orderId: Order_ID })
                })

            }).then(() => {
                let headers = new Headers();

                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('Origin', 'http://localhost:3000');
                //headers.append('Origin','https://frontend-test-bbo.web.app/')
                headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
                fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/confirmMarktcommissieIntern", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({})
                })


            }).then(() => {
                setTimeout(() => {
                    this.setState({
                        loader: false,
                        redirect: true
                    })

                }, 2000)
            })
        }, 2000);




    }

    checkGeneral() {
        this.setState({ genrale: this.state.genrale ? false : true });

    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={'/bedankt'} />
        }


        return (

            <Container className="home-page">

                <div className="row">
                    <div className="col-sm-6">
                        <h2>Inschrijving Jaarmarkt</h2>
                        <span className="input-field-request">
                            <label>Naam bedrijf: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Achternaam: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.lastname} onChange={event => this.setState({ lastname: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Voornaam/ Voorletters: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.firstname} onChange={event => this.setState({ firstname: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>E-mailadres: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.email} onChange={event => this.setState({ email: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Telefoon: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage: '' })} />
                        </span>
                    </div>
                    <div className="col-sm-6 right-colum-request">

                        <span className="input-field-request ">
                            <label>Adres: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.adres} onChange={event => this.setState({ adres: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Postcode: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.postal} onChange={event => this.setState({ postal: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Plaats: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.city} onChange={event => this.setState({ city: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Land: </label>
                            <br />
                            <select value={this.state.country} onChange={(event) => this.setState({ country: event.target.value })}>
                                <option>Selecteer een land</option>
                                <option value="Nederland" >Nederland</option>
                                <option value="België">België</option>
                                <option value="Duitsland">Duitsland</option>
                            </select>

                        </span>
                    </div>
                    <div className="col-sm-6 request-col">

                        <h2>Bedrijfsgegevens</h2>
                        <span className="input-field-request">
                            <label>Naam bedrijf: </label>
                            <br />
                            <input disabled required type="text" name="company-name" value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>KVK-nummer: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.kvk} onChange={event => this.setState({ kvk: event.target.value, errorMessage: '' })} />
                        </span>

                        <span className="input-field-request">
                            <label>Te verhandelen artikelen: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.arcticles} onChange={event => this.setState({ arcticles: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Food of Non-Food: </label>
                            <br />
                            <select value={this.state.typeFood} onChange={(event) => this.setState({ typeFood: event.target.value })}>
                                <option>Maak een keuze</option>
                                <option value="Food" >Food</option>
                                <option value="Non-food">Non-Food</option>
                            </select>
                        </span>
                    </div>
                    <div className="col-sm-6 request-col">

                        <h2>Standplek</h2>
                        <span className="input-field-request">
                            <label>Reservering voor: </label>
                            <br />
                            <select value={this.state.typeShop} onChange={(event) => this.setState({ typeShop: event.target.value })}>
                                <option value="">Maak een keuze</option>
                                <option value="Standaard marktkraam zonder zeil" >Standaard marktkraam zonder zeil (€ 55,-- per stuk)</option>
                                <option value="Grondplaats voor eigen materiaal">Grondplaats voor eigen materiaal (€ 10,50  per meter)</option>
                                <option value="Grondplaats voor verkoopwagen">Grondplaats voor verkoopwagen  (€ 10,50  per meter. Totale lengte incl. dissel)</option>
                            </select>
                        </span>

                        {this.state.typeShop != '' ?
                            this.state.typeShop == 'Standaard marktkraam zonder zeil' ?
                                <span className="input-field-request">
                                    <label>Aantal: </label>
                                    <br />
                                    <input required type="number" min="0" name="company-name" value={this.state.shopAmount} onChange={event => this.setState({ shopAmount: event.target.value, errorMessage: '' })} />
                                    <label style={{ width: '100%', marginTop: '-2%' }}><input type="checkbox" checked={this.state.extraMeter} onClick={() => this.setState({ extraMeter: this.state.extraMeter ? false : true })} /> Ik wil graag naast de marktkraam extra grondplaats voor eigen materiaal (€ 10,50 p.m.).</label>
                                    {this.state.extraMeter ?
                                        <span className="input-field-request"> <label>Aantal meter: </label>
                                            <br />
                                            <input required type="number" min="0" name="company-name" value={this.state.shopAmountExtra} onChange={event => this.setState({ shopAmountExtra: event.target.value, errorMessage: '' })} />
                                        </span> : null}
                                </span>
                                : <span className="input-field-request">
                                    <label>Aantal meter: </label>
                                    <br />
                                    <input required type="number" min="0" name="company-name" value={this.state.shopAmount} onChange={event => this.setState({ shopAmount: event.target.value, errorMessage: '' })} />
                                </span> : null}
                        {this.state.typeShop != '' ?
                            this.state.typeShop == 'Standaard marktkraam zonder zeil' ? null :
                                <span className="input-field-request">
                                    <label>B x D x H in meter: </label>
                                    <br />
                                    <input required type="text" name="company-name" value={this.state.carSize} onChange={event => this.setState({ carSize: event.target.value, errorMessage: '' })} />
                                </span> : null}


                        <span className="input-field-request">
                            <label>Elektriciteit nodig: </label>
                            <br />
                            <select value={this.state.electricity} onChange={(event) => this.setState({ electricity: event.target.value })}>
                                <option>Maak een keuze</option>
                                <option value="Ja" >Ja</option>
                                <option value="Nee">Nee</option>
                            </select>
                        </span>
                        {this.state.electricity == 'Ja' ?
                            <span>
                                <span className="input-field-request">
                                    <label>Benodigde aansluiting: </label>
                                    <br />
                                    <select value={this.state.electricityConnection} onChange={(event) => this.setState({ electricityConnection: event.target.value })}>
                                        <option>Maak een keuze</option>
                                        <option value="230Volt" >230Volt</option>
                                        <option value="400Volt">400Volt</option>
                                    </select>

                                </span>
                                <span className="input-field-request">
                                    <label>Elektrisch vermogen (Watt): </label>
                                    <br />
                                    <input required type="text" name="company-name" value={this.state.electricityWattage} onChange={event => this.setState({ electricityWattage: event.target.value, errorMessage: '' })} />
                                </span>
                            </span> : null}

                        <span className="input-field-request">
                            <label>Was u reeds op onze markt: </label>
                            <br />
                            <select value={this.state.alreadyBeenThere} onChange={(event) => this.setState({ alreadyBeenThere: event.target.value })}>
                                <option>Maak een keuze</option>
                                <option value="Ja" >Ja</option>
                                <option value="Nee">Nee</option>
                            </select>

                        </span>
                        <span className="input-field-request">
                            <label>E-mailadres ter controle: </label>
                            <br />

                            <input required type="text" name="company-name" value={this.state.emailSecond} onChange={event => this.controlSecondEmail(event.target.value)} />


                        </span>
                        <span className="input-field-request">
                            <label>Opmerkingen: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.desc} onChange={event => this.setState({ desc: event.target.value, errorMessage: '' })} />
                        </span>







                    </div>
                    <div className="col-sm-6">

                    </div>
                    <div className="col-sm-6 button-col">
                        <label style={{ width: '100%', marginTop: '-2%' }}><input type="checkbox" checked={this.state.informationCorrect} onClick={() => this.setState({ informationCorrect: this.state.informationCorrect ? false : true })} /> Ik heb alle gegevens correct ingevuld.</label>
                        <label style={{ width: '100%', marginTop: '-2%' }}><input type="checkbox" checked={this.state.genrale} onClick={() => this.checkGeneral()} /> Ik heb kennis genomen van de <a href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/marktcom%2FSMB%20Algemene%20voorwaarden%20deelname%20Jaarmarkt%20Beek%20en%20Baek%20Voiture%202024.pdf?alt=media&token=f1f1f9dc-5cec-4983-a36d-e5a3fad1f06b" target="_blank">SMB algemene voorwaarden</a>.</label>
                        <label style={{ width: '100%', marginTop: '-2%' }}><input type="checkbox" checked={this.state.policy} onClick={() => this.setState({ policy: this.state.policy ? false : true })} /> Ik heb kennis genomen van de <a href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/marktcom%2FSMB%20Privacy%20Policy%20-%202024.pdf?alt=media&token=ebc98ffa-7805-4b5e-ad27-0fe9b8727848" target="_blank">SMB privacy policy.</a></label><br /><br />
                        {this.state.errorMail
                            ? <div className='warning'>
                                <p>E-mail adres komt niet overeen met eerder opgegeven adres.</p>
                            </div> : null}
                        {this.state.loader ? <button className="default-button-marktcommisie"><Loader type="Bars" color="#fff" height={20} width={20} /></button>
                            : this.state.genrale == true
                                && this.state.errorMail == false
                                && this.state.informationCorrect == true
                                && this.state.policy == true
                                && this.state.companyName != ''
                                && this.state.lastname != ''
                                && this.state.firstname != ''
                                && this.state.email != ''
                                && this.state.phone != ''
                                && this.state.adres != ''
                                && this.state.postal != ''
                                && this.state.city != ''
                                && this.state.kvk != ''
                                && this.state.arcticles != ''
                                && this.state.typeFood != ''
                                && this.state.typeShop != ''
                                && this.state.alreadyBeenThere != ''

                                ? <button disabled className="default-button-marktcommisie" onClick={() => this.sendRequest()}>Verzend aanvraag</button> : <button disabled className="default-button-marktcommisie disabled-button" onClick={() => this.sendRequest()}>Verzend aanvraag</button>}
                    </div>
                    <div className="col-sm-12 request-overview-col">
                        <h2>Overzicht</h2>
                        <div className="row">
                            <div className="col-sm-6 request-overview-detail">
                                <h3>Algemene gegevens</h3>
                                <ul>
                                    <li><span className="left-li">Naam bedrijf:</span><span className="right-li">{this.state.companyName}</span></li>
                                    <li><span className="left-li">Voornaam + Achternaam:</span><span className="right-li">{this.state.firstname} {this.state.lastname}</span></li>
                                    <li><span className="left-li">E-mail:</span><span className="right-li">{this.state.email}</span></li>
                                    <li><span className="left-li">Telefoon:</span><span className="right-li">{this.state.phone}</span></li>
                                    <li><span className="left-li">Adres:</span><span className="right-li">{this.state.adres} , {this.state.postal}, {this.state.city}, {this.state.country}</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-6 request-overview-detail">
                                <h3>Bedrijfsgegevens</h3>
                                <ul>
                                    <li><span className="left-li">Naam bedrijf:</span><span className="right-li">{this.state.companyName}</span></li>
                                    <li><span className="left-li">KVK-nummer:</span><span className="right-li">{this.state.kvk}</span></li>
                                    <li><span className="left-li">Artikelen:</span><span className="right-li">{this.state.arcticles}</span></li>
                                    <li><span className="left-li">Food of Non-food:</span><span className="right-li">{this.state.typeFood}</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-6 request-overview-detail">
                                <h3>Standplek</h3>
                                <ul>
                                    <li><span className="left-li">Reservering voor:</span><span className="right-li">{this.state.typeShop} {this.state.extraMeter ? '& ' + this.state.shopAmountExtra + ' meter extra grondplaats' : null} </span></li>
                                    <li><span className="left-li">Hoeveelheid:</span><span className="right-li">{this.state.shopAmount} {this.state.typeAmountShop}</span></li>
                                    {this.state.typeShop == 'Standaard marktkraam zonder zeil' ? null : <li><span className="left-li">Elektriciteit nodig:</span><span className="right-li">{this.state.electricity}</span></li>}
                                    {this.state.typeShop == 'Standaard marktkraam zonder zeil' ? null : <li><span className="left-li">Benodigde aansluiting:</span><span className="right-li">{this.state.electricityConnection}</span></li>}
                                    {this.state.typeShop == 'Standaard marktkraam zonder zeil' ? null : <li><span className="left-li">Elektrisch vermogen:</span><span className="right-li">{this.state.electricityWattage}</span></li>}
                                    {this.state.typeShop == 'Standaard marktkraam zonder zeil' ? null : <li><span className="left-li">B X D X H in meter: </span><span className="right-li">{this.state.carSize}</span></li>}
                                    <li><span className="left-li">Was u reeds op onze markt: </span><span className="right-li">{this.state.alreadyBeenThere}</span></li>
                                </ul>
                            </div>
                            <div className="col-sm-6 request-overview-detail">
                                <h3>Opmerkingen</h3>
                                <textarea value={this.state.desc} disabled />
                            </div>
                        </div>


                    </div>

                </div>
            </Container>

        );
    }
};

export default FrontendHomeComponent;